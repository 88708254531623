@import "../../base/variables";


/*
 * 广告图
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.index-banner {
  // height: 400px;
  position: relative;
  top: -30px;
  .slick-slide {
    height: 400px;
    a {
      position: absolute;
      left: 50%;
    }
    img {
      width: auto;
      height: auto;
      position: relative;
      left: -50%;
    }
  }
  .slick-dots {
    text-align: right;
    right: 30px;
  }
  .slick-dots .contain {
    width: 1160px;
    margin: 0 auto;
  }
}


/*
 * 精选
 * 
 * @since 2018-5-19
 * @author xieyun
 */

/*精选-左侧*/
// .index-recommend {
//   padding-top: 30px;
// }
.index-recommend .recommend-left {
  width: 440px;
  background-color: $bg;
}
.recommend-left .left-title {
  font-weight: bold;
  margin: 30px;
}

.recommend-left .slick {
  margin: 0 15px;
  padding-bottom: 88px;
}
.recommend-left .slick-dots {
  height: auto;
  bottom: 35px;
  li {
    margin: 0 8px;
    width: 6px;
    height: 6px;
    -ms-transform:rotate(45deg);
    -moz-transform: rotate(45deg); 
    -webkit-transform: rotate(45deg); 
    -o-transform: rotate(45deg); 
    button {
      width: 6px;
      height: 6px;
      padding: 0;
      background-color: $bg-indicators;
    }
  }
  li.slick-active {
    button{
      background-color: $color-skin-base;
    } 
  }
}
.recommend-left .good-item {
  padding: 0 40px;
  .item-wrapper {
    display: block;
    padding-bottom: 25px;
  }
  .item-avatar {
    width: 330px;
    margin: 0 auto;
    height: 330px;
    // border: 1px solid $border-lighter;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item-info {
    text-align: center;
    .title {
      display: block;
      height: 39px;
    }
    .price {
      display: block;
      color: $text-price;
    }
  }
}
.recommend-left .slick-prev,
.recommend-left .slick-next {
  width: 40px;
  color: $font-light;
  top: 220px;
  i {
    font-size: 20px;
  }
}

/*精选-右侧*/
.index-recommend .recommend-right {
  width: 715px;
  margin-left: 5px;
}
.recommend-right .gx-good-item {
  .item-avatar {
    width: 226px;
    height: 226px;
    border-bottom: 0 none;
  }
  .item-info {
    margin-top: 15px;
  }
}


/*
 * 新品首发
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.index-new {
  width: 1180px;
  padding-bottom: 10px;
}
.index-new .new-title {
  padding: 30px 0 20px;
  font-weight: bold;
}

.index-new .slick-prev,
.index-new .slick-next {
  width: 46px;
  text-align: center;
  height: 46px;
  line-height: 46px;
  border-radius: 46px;
  color: $font-lighter;
  background-color: $color-skin-base;
  top: 50%;
  margin-top: -28px;
  &:hover {
    background-color: $color-skin-dark;
  }

  i {
    font-size: 20px;
  }
}
.index-new .slick-prev {
  left: -66px;
}
.index-new .slick-next {
  right: -66px;
}

.index-new .gx-good-item {
  width: 100%;
  .item-wrapper {
    margin-left: 0;
    margin-right: 20px;
    border: 0 none;
    &:hover {
      box-shadow: 0px 4px 12px 0px $color-skin-lighter;
      opacity: 1 !important;
      border: 0 none;
      background-color: rgba($color-skin-base, 0.1);
    }

    .item-info {
      margin-bottom: 0;
      padding-bottom: 20px;
    }
  }

  .item-avatar {
    width: 275px;
    height: 275px;
  }
}


/*
 * 商品
 * 
 * @since 2018-5-19
 * @author xieyun
 */

/*商品-标题*/
.index-good .good-header {
  position: relative;
  padding: 30px 0 15px;
  .header-title {
    font-size: 0;
    .icon,
    .title {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      width: 30px;
      height: 30px;
    }
    .icon1 {
      background: url('../../../img/classify/classify-2.png') no-repeat center top;
    }
    .icon2 {
      background: url('../../../img/classify/classify-4.png') no-repeat center top;
    }
    .icon3 {
      background: url('../../../img/classify/classify-5.png') no-repeat center top;
    }
    .icon4 {
      // background: url('../../../img/classify/classify-4.png') no-repeat center top;
    }
    .icon5 {
      // background: url('../../../img/classify/classify-5.png') no-repeat center top;
    }
  }

  .header-more {
    position: absolute;
    top: 37px;
    right: 0;
    color: $font-base;
    font-size: 13px;
    &:hover {
      color: $color-skin-base;
    }
  }
}

/*商品-列表*/
.index-good .good-content {
  .good-advert {
    width: 224px;
    height: 620px;
    a {
      display: block;
    }
  }
  .good-list {
    width: 936px;
  }
  .gx-good-item {
    .item-avatar {
      width: 222px;
      height: 223px;
    }
  }
}


/*
 * 绑定手机
 * 
 * @since 2018-6-28
 * @author xieyun
 */
.gx-modal-dialog {
  .modal-body {
    .form-error {
      width: 300px;
      height: 30px;
      line-height: 30px;
    }
    .form-control {
      width: 300px;
    }
  }
}