@charset "UTF-8";
/*
 * 广告图
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.index-banner {
  position: relative;
  top: -30px; }
  .index-banner .slick-slide {
    height: 400px; }
    .index-banner .slick-slide a {
      position: absolute;
      left: 50%; }
    .index-banner .slick-slide img {
      width: auto;
      height: auto;
      position: relative;
      left: -50%; }
  .index-banner .slick-dots {
    text-align: right;
    right: 30px; }
  .index-banner .slick-dots .contain {
    width: 1160px;
    margin: 0 auto; }

/*
 * 精选
 * 
 * @since 2018-5-19
 * @author xieyun
 */
/*精选-左侧*/
.index-recommend .recommend-left {
  width: 440px;
  background-color: #fff; }

.recommend-left .left-title {
  font-weight: bold;
  margin: 30px; }

.recommend-left .slick {
  margin: 0 15px;
  padding-bottom: 88px; }

.recommend-left .slick-dots {
  height: auto;
  bottom: 35px; }
  .recommend-left .slick-dots li {
    margin: 0 8px;
    width: 6px;
    height: 6px;
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg); }
    .recommend-left .slick-dots li button {
      width: 6px;
      height: 6px;
      padding: 0;
      background-color: #ccc; }
  .recommend-left .slick-dots li.slick-active button {
    background-color: #5e5ecb; }

.recommend-left .good-item {
  padding: 0 40px; }
  .recommend-left .good-item .item-wrapper {
    display: block;
    padding-bottom: 25px; }
  .recommend-left .good-item .item-avatar {
    width: 330px;
    margin: 0 auto;
    height: 330px; }
    .recommend-left .good-item .item-avatar img {
      width: 100%;
      height: 100%; }
  .recommend-left .good-item .item-info {
    text-align: center; }
    .recommend-left .good-item .item-info .title {
      display: block;
      height: 39px; }
    .recommend-left .good-item .item-info .price {
      display: block;
      color: #db0000; }

.recommend-left .slick-prev,
.recommend-left .slick-next {
  width: 40px;
  color: #999;
  top: 220px; }
  .recommend-left .slick-prev i,
  .recommend-left .slick-next i {
    font-size: 20px; }

/*精选-右侧*/
.index-recommend .recommend-right {
  width: 715px;
  margin-left: 5px; }

.recommend-right .gx-good-item .item-avatar {
  width: 226px;
  height: 226px;
  border-bottom: 0 none; }

.recommend-right .gx-good-item .item-info {
  margin-top: 15px; }

/*
 * 新品首发
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.index-new {
  width: 1180px;
  padding-bottom: 10px; }

.index-new .new-title {
  padding: 30px 0 20px;
  font-weight: bold; }

.index-new .slick-prev,
.index-new .slick-next {
  width: 46px;
  text-align: center;
  height: 46px;
  line-height: 46px;
  border-radius: 46px;
  color: #fff;
  background-color: #5e5ecb;
  top: 50%;
  margin-top: -28px; }
  .index-new .slick-prev:hover,
  .index-new .slick-next:hover {
    background-color: #42428e; }
  .index-new .slick-prev i,
  .index-new .slick-next i {
    font-size: 20px; }

.index-new .slick-prev {
  left: -66px; }

.index-new .slick-next {
  right: -66px; }

.index-new .gx-good-item {
  width: 100%; }
  .index-new .gx-good-item .item-wrapper {
    margin-left: 0;
    margin-right: 20px;
    border: 0 none; }
    .index-new .gx-good-item .item-wrapper:hover {
      box-shadow: 0px 4px 12px 0px #e5e5f7;
      opacity: 1 !important;
      border: 0 none;
      background-color: rgba(94, 94, 203, 0.1); }
    .index-new .gx-good-item .item-wrapper .item-info {
      margin-bottom: 0;
      padding-bottom: 20px; }
  .index-new .gx-good-item .item-avatar {
    width: 275px;
    height: 275px; }

/*
 * 商品
 * 
 * @since 2018-5-19
 * @author xieyun
 */
/*商品-标题*/
.index-good .good-header {
  position: relative;
  padding: 30px 0 15px; }
  .index-good .good-header .header-title {
    font-size: 0; }
    .index-good .good-header .header-title .icon,
    .index-good .good-header .header-title .title {
      display: inline-block;
      vertical-align: middle; }
    .index-good .good-header .header-title .icon {
      width: 30px;
      height: 30px; }
    .index-good .good-header .header-title .icon1 {
      background: url("../../../img/classify/classify-2.png") no-repeat center top; }
    .index-good .good-header .header-title .icon2 {
      background: url("../../../img/classify/classify-4.png") no-repeat center top; }
    .index-good .good-header .header-title .icon3 {
      background: url("../../../img/classify/classify-5.png") no-repeat center top; }
  .index-good .good-header .header-more {
    position: absolute;
    top: 37px;
    right: 0;
    color: #666;
    font-size: 13px; }
    .index-good .good-header .header-more:hover {
      color: #5e5ecb; }

/*商品-列表*/
.index-good .good-content .good-advert {
  width: 224px;
  height: 620px; }
  .index-good .good-content .good-advert a {
    display: block; }

.index-good .good-content .good-list {
  width: 936px; }

.index-good .good-content .gx-good-item .item-avatar {
  width: 222px;
  height: 223px; }

/*
 * 绑定手机
 * 
 * @since 2018-6-28
 * @author xieyun
 */
.gx-modal-dialog .modal-body .form-error {
  width: 300px;
  height: 30px;
  line-height: 30px; }

.gx-modal-dialog .modal-body .form-control {
  width: 300px; }
